import {
  Badge,
  Button,
  Layout,
  LegacyCard,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useStore } from "./hooks/useStore";
import { Store } from "../../../../../types/common.types";
import StoreDetails from "./components/StoreDetails/StoreDetails";
import StoreCRMDetails from "./components/StoreCRMDetails/StoreCRMDetails";

export default function StorePage({ userRole }: { userRole: string }) {
  const { clientId, storeId } = useParams();
  const { store, isLoading } = useStore(clientId as string, storeId as string);

  if (isLoading)
    return (
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <LegacyCard sectioned>
              <SkeletonBodyText />
            </LegacyCard>
            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      backAction={{
        url: `/${userRole}/clients/${clientId}`,
      }}
      titleMetadata={
        <Badge tone="success">
          {store?.bank_site ? "Bank site" : "Main site"}
        </Badge>
      }
      title={store?.name}
      primaryAction={<Button>Delete</Button>}
    >
      <Layout>
        <Layout.Section variant="oneHalf">
          <StoreDetails
            clientId={clientId as string}
            store={store as Store}
            userRole={userRole}
          />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <StoreCRMDetails
            clientId={clientId as string}
            store={store as Store}
            userRole={userRole}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

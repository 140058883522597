import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";
import { Store } from "../../../../../../types/common.types";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";

async function getStore(clientId: string, storeId: string): Promise<Store> {
  const { data } = await axiosInstance.get(
    `/clients/${clientId}/stores/${storeId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );

  return data;
}

interface Props {
  isLoading: boolean;
  isError: boolean;
  store: Store | null;
}

export function useStore(clientId: string, storeId: string): Props {
  const {
    data: store = null,
    isLoading,
    isError,
  } = useQuery(
    [queryKeysConstants.clients, clientId, "stores", storeId],
    () => getStore(clientId, storeId),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isError,
    store,
  };
}

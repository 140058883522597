import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../../../services/axios.service";
import { StoreCRMDetails } from "../../../../../../../../types/common.types";
import { getAccessToken } from "../../../../../../../../services/auth.service";

async function updateStoreCRMDetails(
  clientId: string,
  storeId: string,
  crmDetails: StoreCRMDetails,
): Promise<void> {
  await axiosInstance.put(
    `/clients/${clientId}/stores/${storeId}/crm_details`,
    crmDetails,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useUpdateStoreCRMDetails(
  clientId: string,
  storeId: string,
): UseMutateAsyncFunction<void, unknown, StoreCRMDetails, unknown> {
  const { mutateAsync } = useMutation((crmDetails: StoreCRMDetails) =>
    updateStoreCRMDetails(clientId, storeId, crmDetails),
  );

  return mutateAsync;
}

import "@shopify/polaris/build/esm/styles.css";
import "@shopify/polaris-viz/build/esm/styles.css";
import { Spinner } from "@shopify/polaris";
import { Navigate, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { Protected, NotProtected } from "./components/AuthHelpers";
import Auth from "./layout/Auth";
import { auth } from "./services/firebase.service";
import ManagerDashboard from "./layout/ManagerDashboard";
import { useUser } from "./pages/Auth/Login/hooks/useUser";
import AdminDashboard from "./layout/AdminDashboard";

function App() {
  const [loading, setLoading] = useState(true);

  const [authUser, , error] = useAuthState(auth);
  const { user, isLoading } = useUser();

  const isLoggedIn = !!authUser && !error;

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }
  }, [isLoading]);

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );

  const redirectTo =
    user?.role === "admin" ? "/admin/home" : "/manager/clients";

  return (
    <>
      <Routes>
        <Route
          path="/auth/*"
          element={
            <NotProtected isLoggedIn={isLoggedIn} redirectTo={redirectTo}>
              <Auth />
            </NotProtected>
          }
        />
        {user?.role === "admin" && (
          <Route
            path="/admin/*"
            element={
              <Protected isLoggedIn={isLoggedIn} redirectTo="/auth/login">
                <AdminDashboard />
              </Protected>
            }
          />
        )}
        {user?.role === "manager" && (
          <Route
            path="/manager/*"
            element={
              <Protected isLoggedIn={isLoggedIn} redirectTo="/auth/login">
                <ManagerDashboard />
              </Protected>
            }
          />
        )}
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? redirectTo : "/auth/login"} />}
        />
      </Routes>
      <ReactQueryDevtools />
    </>
  );
}

export default App;

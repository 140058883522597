import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { Store } from "../../../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../../../services/auth.service";

async function updateStore(clientId: string, store: Store): Promise<void> {
  await axiosInstance.put(`/clients/${clientId}/stores/${store.id}`, store, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useUpdateStore(
  clientId: string,
): UseMutateAsyncFunction<void, unknown, Store, unknown> {
  const { mutateAsync } = useMutation((store: Store) =>
    updateStore(clientId, store),
  );

  return mutateAsync;
}

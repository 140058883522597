import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";
import { Page, Store } from "../../../../../../types/common.types";

async function getStores(
  clientId: string,
  page = 1,
  size = 10,
): Promise<Page<Store>> {
  const { data } = await axiosInstance.get(`/clients/${clientId}/stores/`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: {
      size,
      page,
    },
  });

  return data;
}

interface Props {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  stores: Store[];
}

export function useStores(clientId: string, size = 10): Props {
  const [page, setPage] = useState(1);

  const { data, isFetching, isLoading } = useQuery(
    [queryKeysConstants.clients, clientId, "stores", page, size],
    () => getStores(clientId, page, size),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    stores: data?.items || [],
  };
}

import {
  Page,
  IndexTable,
  Link,
  Badge,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  TabProps,
  Card,
} from "@shopify/polaris";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useClients } from "../AdminDashboard/Clients/hooks/useClients";
import { ClientStatus } from "../../types/clients.types";
import {
  formatConstant,
  formatDate,
  getBadgeColorBasedOnClientStatus,
} from "../../helpers/helpers";

export default function ClientsPageForManager() {
  const navigate = useNavigate();
  const { mode, setMode } = useSetIndexFiltersMode();

  const [selected, setSelected] = useState(0);
  const [clientsStatus, setClientsStatus] = useState("ACTIVE");
  const [queryValue, setQueryValue] = useState("");

  const { minPage, maxPage, isFetching, page, setPage, clients } = useClients(
    10,
    clientsStatus,
    queryValue,
  );
  const columns = ["Client name", "Company name", "Status", "Started at"];

  const tabs: TabProps[] = [
    {
      id: ClientStatus.ACTIVE,
      content: "Active",
    },
    {
      id: ClientStatus.PENDING,
      content: "Pending",
    },
    {
      id: ClientStatus.DISABLED,
      content: "Inactive",
    },
    {
      id: "ALL",
      content: "All",
    },
  ];

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setClientsStatus(tabs[value].id);
  }, []);

  const rowMarkup = clients.map((client, index) => (
    <IndexTable.Row id={client.id as string} key={client.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          monochrome
          dataPrimaryLink
          onClick={() => navigate(`/manager/clients/${client.id}`)}
        >
          <Text as="span" fontWeight="bold">
            {client.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{client.company_name}</IndexTable.Cell>
      <IndexTable.Cell>
        <Badge tone={getBadgeColorBasedOnClientStatus(client.status)}>
          {formatConstant(client.status)}
        </Badge>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatDate(client.started_at, true, true)}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page fullWidth title="Clients">
      <Card padding="0">
        <IndexFilters
          queryValue={queryValue}
          queryPlaceholder="Searching"
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => {}}
          cancelAction={{
            onAction: () => {},
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          filters={[]}
          onClearAll={() => {}}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: "Client",
            plural: "Clients",
          }}
          itemCount={clients.length}
          loading={isFetching}
          headings={
            columns.map(
              (column: string): IndexTableHeading => ({ title: column }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
    </Page>
  );
}

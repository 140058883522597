import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";
import { Store } from "../../../../../../types/common.types";

async function addStore(clientId: string, store: Store): Promise<void> {
  await axiosInstance.post(`/clients/${clientId}/stores/`, store, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useAddStore(
  clientId: string,
): UseMutateAsyncFunction<void, unknown, Store, unknown> {
  const { mutateAsync } = useMutation((store: Store) =>
    addStore(clientId, store),
  );

  return mutateAsync;
}

export enum PaymentSchedule {
  BI_WEEKLY = "bi_weekly",
  MONTHLY = "monthly",
  HALF_MONTHLY = "half_monthly",
}

export interface Address {
  street_address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export enum CRMType {
  ZENDESK = "ZENDESK",
  FRESHDESK = "FRESHDESK",
}

export enum ClientStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}

export interface CRMDetails {
  type: CRMType;
  subdomain: string;
  email: string;
  password: string;
}

export interface Client {
  id: string | null;

  payment_schedule: PaymentSchedule | null;

  started_at: Date;
  last_billed_at: Date | null;
  next_billing_at: Date | null;

  name: string;
  email: string;
  company_name?: string;
  monthly_rate: number | 0;
  billing_address?: Address;

  total_stores: number;
  status: ClientStatus;

  crm_details: CRMDetails | null;
  stripe_customer_id?: string;
  telegram_channel_id?: string;
}

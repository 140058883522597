import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldInput from "../../../../../components/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import {
  Client,
  CRMDetails,
  CRMType,
} from "../../../../../types/clients.types";
import SelectInput from "../../../../../components/SelectInput";
import { capitalize } from "../../../../../helpers/helpers";
import { useUpdateClientCRMDetails } from "./hooks/useUpdateClientCRMDetails";

interface EditZendeskDetailsModalProps {
  client: Client;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    type: yup.string().required(),
    subdomain: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

export default function EditCRMDetailsModal({
  client,
  active,
  setActive,
}: EditZendeskDetailsModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset } = useForm<CRMDetails>({
    resolver: yupResolver(schema),
  });
  const updateCRMDetails = useUpdateClientCRMDetails(client.id as string);

  const handleClose = () => {
    reset();
    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (crmDetails: CRMDetails) => {
    setLoading(true);

    try {
      await updateCRMDetails(crmDetails);

      await queryClient.invalidateQueries([
        queryKeysConstants.clients,
        client.id,
      ]);

      handleClose();

      onShowToast("CRM details saved", false);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setLoading(false);
  };

  useEffect(() => {
    reset({
      type: client.crm_details?.type || CRMType.ZENDESK,
      subdomain: client.crm_details?.subdomain || "",
      email: client.crm_details?.email || "",
      password: client.crm_details?.password || "",
    });
  }, [client]);

  return (
    <Modal
      size="small"
      open={active}
      onClose={handleClose}
      title="Edit zendesk details"
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <SelectInput
            control={control}
            name="type"
            label="Type"
            options={Object.values(CRMType).map((val) => ({
              label: capitalize(val.toLowerCase()),
              value: val,
            }))}
          />
          <TextFieldInput
            control={control}
            name="subdomain"
            label="Subdomain"
          />
          <TextFieldInput control={control} name="email" label="Email" />
          <TextFieldInput control={control} name="password" label="Password" />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

import {
  BlockStack,
  Button,
  Card,
  FormLayout,
  InlineGrid,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Client } from "../../../../../types/clients.types";
import { GridField } from "../../../../../components/GridField";
import { formatConstant, formatDate } from "../../../../../helpers/helpers";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import TextFieldInput from "../../../../../components/TextFieldInput";
import SelectInput from "../../../../../components/SelectInput";

const schema = yup
  .object({
    stripe_customer_id: yup.string().required(),
    payment_schedule: yup.string().required(),
  })
  .required();

export default function InternalDetails({
  client,
  userRole,
}: {
  client: Client;
  userRole: string;
}) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm<Client>({
    defaultValues: {
      stripe_customer_id: client.stripe_customer_id,
      payment_schedule: client.payment_schedule,
    },
    resolver: yupResolver(schema),
  });

  const onHandleSubmit = async (updatedClient: Client) => {
    setLoading(true);

    try {
      // await updateAgent({
      //   ...agent,
      //   discord_id: updatedAgent.discord_id,
      //   internal_email: updatedAgent.internal_email,
      //   internal_email_password: updatedAgent.internal_email_password,
      // });

      // await queryClient.invalidateQueries([
      //   queryKeysConstants.clients,
      //   client.id,
      // ]);

      console.log(updatedClient);

      onShowToast("Client Updated", false);

      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="200">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            Internal Details
          </Text>
          {userRole === "admin" && (
            <Button
              variant="plain"
              onClick={() => setEdit((prevState: boolean) => !prevState)}
              loading={loading}
            >
              {edit ? "Cancel" : "Edit"}
            </Button>
          )}
        </InlineStack>
        {!edit && (
          <InlineGrid columns="2" gap="300">
            {userRole === "admin" && (
              <GridField name="Stripe Customer ID">
                {client.stripe_customer_id}
              </GridField>
            )}
            <GridField name="Status">{formatConstant(client.status)}</GridField>
            {userRole === "admin" && (
              <>
                <GridField name="Payment Schedule">
                  {formatConstant(client.payment_schedule as string)}
                </GridField>
                <GridField name="Last billed at">
                  {client.last_billed_at
                    ? formatDate(client.last_billed_at, true, true)
                    : "-"}
                </GridField>
                <GridField name="Next billing at">
                  {client.next_billing_at
                    ? formatDate(client.next_billing_at, true, true)
                    : "-"}
                </GridField>
              </>
            )}
          </InlineGrid>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <TextFieldInput
                control={control}
                name="stripe_customer_id"
                label="Stripe customer ID"
              />
              <SelectInput
                control={control}
                name="payment_schedule"
                label="Payment Schedule"
                options={[
                  { label: "Monthly", value: "MONTHLY" },
                  { label: "Half Monthly", value: "HALF_MONTHLY" },
                ]}
              />
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                Save
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}

import moment from "moment/moment";
import { ClientStatus } from "../types/clients.types";

export function getObjectValueWithDotNotation(object: any, keys: string): any {
  return keys.split(".").reduce((o: any, k: string) => (o || {})[k], object);
}

export function formatMoney(value: number): string {
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: "USD",
  }).format(value);
}

export function downloadFile(blob: any, filename: string) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);

  URL.revokeObjectURL(url);
}

export function sumArray(array: any[number]): number {
  return array.reduce((partialSum: number, a: number) => partialSum + a, 0);
}

export const capitalize = <T extends string>(s: T) =>
  (s[0].toUpperCase() + s.slice(1)) as Capitalize<typeof s>;

export function formatConstant(value: string): string {
  return capitalize(value.toLowerCase().replace("_", " "));
}

export function formatDate(
  value: Date | undefined | null,
  onlyDate = false,
  simpleFormat = false,
  forceFromNow = false,
): string {
  if (!value) return "-";

  if (forceFromNow || (moment.utc(value) > moment() && !simpleFormat)) {
    return capitalize(moment.utc(value).locale("fr-FR").fromNow());
  }

  let format = "MMMM Do YYYY, HH:mm";

  if (onlyDate) {
    format = "MMMM Do YYYY";
  }

  return capitalize(moment.utc(value).locale("fr-FR").format(format));
}

export function getBadgeColorBasedOnClientStatus(clientStatus: ClientStatus) {
  switch (clientStatus) {
    case ClientStatus.PENDING:
      return "attention";
    case ClientStatus.ACTIVE:
      return "success";
    case ClientStatus.DISABLED:
      return "critical";
    default:
      return "critical";
  }
}

import {
  Page,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Badge,
  LegacyCard,
  BlockStack,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../AdminDashboard/Clients/hooks/useClient";
import {
  formatConstant,
  getBadgeColorBasedOnClientStatus,
} from "../../helpers/helpers";
import { Client, ClientStatus } from "../../types/clients.types";
import { StoresTable } from "../AdminDashboard/Clients/components/StoresTables/StoresTable";
import CRMDetails from "../AdminDashboard/Clients/components/CRMDetails/CRMDetails";
import InternalDetails from "../AdminDashboard/Clients/components/InternalDetails/InternalDetails";
import ClientContactInformation from "../AdminDashboard/Clients/components/ContactInformation/ClientContactInformation";

export default function ClientPageForManager() {
  const navigate = useNavigate();
  const { clientId } = useParams();

  const { client, isLoading, isError } = useClient(clientId as string);

  if (isError) {
    navigate("/admin/clients");
  }

  if (isLoading)
    return (
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <LegacyCard sectioned>
              <SkeletonBodyText />
            </LegacyCard>
            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      backAction={{
        url: "/manager/clients",
      }}
      titleMetadata={
        <Badge
          tone={getBadgeColorBasedOnClientStatus(
            client?.status as ClientStatus,
          )}
        >
          {formatConstant(client?.status as string)}
        </Badge>
      }
      title={client?.name}
    >
      <Layout>
        <Layout.Section variant="oneHalf">
          <BlockStack gap="200">
            <ClientContactInformation
              client={client as Client}
              userRole="manager"
            />
            <StoresTable clientId={clientId as string} userRole="manager" />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <BlockStack gap="200">
            <InternalDetails client={client as Client} userRole="manager" />
            <CRMDetails client={client as Client} userRole="manager" />
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

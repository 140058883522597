import {
  BlockStack,
  Button,
  Card,
  FormLayout,
  InlineGrid,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Store } from "../../../../../../../types/common.types";
import { GridField } from "../../../../../../../components/GridField";
import TextFieldInput from "../../../../../../../components/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../../../../../contexts/alert.context";
import { useUpdateStore } from "./hooks/useUpdateStore";
import { queryClient } from "../../../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../../../constants/queryKeys.constants";

const schema = yup
  .object({
    name: yup.string().required(),
    url: yup.string().required(),
    support_email: yup.string().nullable(),
    support_phone_number: yup.string().nullable(),
  })
  .required();

export default function StoreDetails({
  clientId,
  store,
  userRole,
}: {
  clientId: string;
  store: Store;
  userRole: string;
}) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm<Store>({
    defaultValues: {
      name: store.name,
      url: store.url,
      support_email: store.support_email,
      support_phone_number: store.support_phone_number,
    },
    resolver: yupResolver(schema),
  });

  const updateStore = useUpdateStore(clientId);

  const onHandleSubmit = async (updatedStore: Store) => {
    setLoading(true);

    try {
      await updateStore({
        ...updatedStore,
        id: store.id,
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.clients,
        clientId,
        "stores",
        store.id,
      ]);

      onShowToast("Store Updated", false);

      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="200">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            Details
          </Text>
          {userRole === "admin" && (
            <Button
              variant="plain"
              onClick={() => setEdit((prevState: boolean) => !prevState)}
              loading={loading}
            >
              {edit ? "Cancel" : "Edit"}
            </Button>
          )}
        </InlineStack>
        {!edit && (
          <InlineGrid columns="1" gap="300">
            <GridField name="Store Name">{store.name}</GridField>
            <GridField name="Store URL">{store.url}</GridField>
            <GridField name="Support Email">{store.support_email}</GridField>
            <GridField name="Support Phone Number">
              {store.support_phone_number}
            </GridField>
            <GridField name="Type">
              {store.bank_site ? "Bank site" : "Main site"}
            </GridField>
          </InlineGrid>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <TextFieldInput
                control={control}
                name="name"
                label="Store Name"
              />
              <TextFieldInput control={control} name="url" label="Store URL" />
              <TextFieldInput
                control={control}
                name="support_email"
                label="Support Email"
              />
              <TextFieldInput
                control={control}
                name="support_phone_number"
                label="Support Phone Number"
              />
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                Save
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}

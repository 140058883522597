import {
  BlockStack,
  Box,
  Button,
  Card,
  IndexTable,
  InlineStack,
  Tag,
  Text,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { SelectionType } from "@shopify/polaris/build/ts/src/utilities/index-provider";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { usePayments } from "./hooks/usePayments";
import {
  downloadFile,
  formatDate,
  formatMoney,
} from "../../../../../helpers/helpers";
import PaymentModal from "./PaymentModal";
import { Payment, PaymentProduct } from "../../../../../types/payments.types";
import { useDeleteClientPayments } from "./hooks/useDeleteClientPayments";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import { Product } from "../../../../../types/products.types";
import { useProducts } from "../../../../../hooks/useProducts";
import { useDownloadReceipts } from "../../../../../hooks/useDownloadReceipts";

export function PaymentsTable({ clientId }: { clientId: string }) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);

  const [modalPayment, setModalPayment] = useState<Payment | null>(null);
  const [paymentModalActive, setPaymentModalActive] = useState(false);

  const { payments, isFetching, page, maxPage, minPage, setPage } =
    usePayments(clientId);

  const deletePayments = useDeleteClientPayments(clientId);
  const downloadReceipts = useDownloadReceipts(clientId, "payments");

  const resourceName = {
    singular: "Payment",
    plural: "Payments",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(
      payments.map((payment: Payment) => ({
        ...payment,
      })),
    );

  const { products, isLoading: isProductsLoading } = useProducts();

  const getProductById = (productId: string): Product | undefined =>
    products.find((product: Product) => {
      return product.id?.match(productId);
    });

  const handleDeletePayments = async () => {
    onShowToast("Deleting Payments", false);

    try {
      await deletePayments(selectedResources);

      handleSelectionChange("all" as SelectionType, false);

      await queryClient.invalidateQueries([
        queryKeysConstants.payments,
        clientId,
      ]);
      await queryClient.invalidateQueries([
        queryKeysConstants.clients,
        clientId,
      ]);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }
  };

  const onHandleDownloadReceipts = async () => {
    onShowToast("Downloading Invoices", false);
    handleSelectionChange("all" as SelectionType, false);

    try {
      const blob = await downloadReceipts(selectedResources);

      downloadFile(blob, "payments-receipts.zip");
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }
  };

  const handlePaymentModalActive = (val: boolean) => {
    setPaymentModalActive(val);

    if (!val) {
      setModalPayment(null);
    }
  };

  const promotedBulkActions = [
    {
      content: "Delete Payment(s)",
      onAction: handleDeletePayments,
    },
    {
      content: "Download Receipts",
      onAction: onHandleDownloadReceipts,
    },
  ];

  const rowMarkup = payments.map((payment, index) => (
    <IndexTable.Row
      id={payment.id as string}
      key={payment.id}
      selected={selectedResources.includes(payment.id as string)}
      position={index}
      onClick={() => {
        setModalPayment(payment);
        setPaymentModalActive(true);
      }}
    >
      <IndexTable.Cell>{formatDate(payment.date_paid)}</IndexTable.Cell>
      <IndexTable.Cell>
        <InlineStack>
          {payment.products.map((product: PaymentProduct, index) => (
            <Tag key={`key${index + 1}`}>
              {getProductById(product.product_id)?.name} - {product.quantity} -{" "}
              {formatMoney(product.price)}
            </Tag>
          ))}
        </InlineStack>
      </IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(payment.total)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              Payments
            </Text>
            <Button variant="plain" onClick={() => setPaymentModalActive(true)}>
              Add
            </Button>
          </InlineStack>
        </Box>
        <IndexTable
          resourceName={resourceName}
          itemCount={payments.length}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          promotedBulkActions={promotedBulkActions}
          headings={[
            { title: "Date" },
            { title: "Products" },
            { title: "Total" },
          ]}
          loading={isFetching || isProductsLoading}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
        <PaymentModal
          payment={modalPayment}
          clientId={clientId as string}
          active={paymentModalActive}
          setActive={handlePaymentModalActive}
        />
      </BlockStack>
    </Card>
  );
}

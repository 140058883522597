import {
  Page,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Badge,
  LegacyCard,
  BlockStack,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import ClientContactInformation from "./components/ContactInformation/ClientContactInformation";
import { useClient } from "./hooks/useClient";
import { StoresTable } from "./components/StoresTables/StoresTable";
import { PaymentsTable } from "./components/PaymentsTable/PaymentsTable";
import { UsagePaymentsTable } from "./components/UsagePaymentsTable/UsagePaymentsTable";
import CRMDetails from "./components/CRMDetails/CRMDetails";
import { Client, ClientStatus } from "../../../types/clients.types";
import InternalDetails from "./components/InternalDetails/InternalDetails";
import {
  formatConstant,
  getBadgeColorBasedOnClientStatus,
} from "../../../helpers/helpers";
import ActivateClientButton from "./components/ActivateClientButton/ActivateClientButton";

export default function ClientPage() {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { client, isLoading, isError } = useClient(clientId as string);

  if (isError) {
    navigate("/admin/clients");
  }

  if (isLoading)
    return (
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <LegacyCard sectioned>
              <SkeletonBodyText />
            </LegacyCard>
            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      backAction={{
        url: "/admin/clients",
      }}
      titleMetadata={
        <Badge
          tone={getBadgeColorBasedOnClientStatus(
            client?.status as ClientStatus,
          )}
        >
          {formatConstant(client?.status as string)}
        </Badge>
      }
      title={client?.name}
      primaryAction={
        client?.status !== ClientStatus.ACTIVE && (
          <ActivateClientButton client={client as Client} />
        )
      }
    >
      <Layout>
        <Layout.Section variant="oneHalf">
          <BlockStack gap="200">
            <ClientContactInformation
              client={client as Client}
              userRole="admin"
            />
            <StoresTable clientId={clientId as string} userRole="admin" />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <BlockStack gap="200">
            <InternalDetails client={client as Client} userRole="admin" />
            <CRMDetails client={client as Client} userRole="admin" />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <UsagePaymentsTable client={client as Client} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <PaymentsTable clientId={clientId as string} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}

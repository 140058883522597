import {
  BlockStack,
  Box,
  Button,
  Card,
  IndexTable,
  InlineStack,
  Link,
  Text,
} from "@shopify/polaris";
import { useState } from "react";
import { Store } from "../../../../../types/common.types";
import { useStores } from "./hooks/useStores";
import NewStoreModal from "./NewStoreModal";

export function StoresTable({
  clientId,
  userRole = "admin",
}: {
  clientId: string;
  userRole: string;
}) {
  const [clientStoreModalActive, setClientStoreModalActive] = useState(false);

  const { stores, isFetching, page, maxPage, minPage, setPage } =
    useStores(clientId);

  const rowMarkup = stores.map((store: Store, index) => (
    <IndexTable.Row id={store.id as string} key={store.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          dataPrimaryLink
          url={`/${userRole}/clients/${clientId}/stores/${store.id}`}
        >
          <Text fontWeight="bold" as="span">
            {store.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {store.bank_site ? "Bank site" : "Main site"}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              Stores
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                setClientStoreModalActive(true);
              }}
            >
              Add
            </Button>
          </InlineStack>
        </Box>
        <IndexTable
          resourceName={{
            singular: "Store",
            plural: "Stores",
          }}
          itemCount={stores.length}
          headings={[{ title: "Name" }, { title: "Type" }]}
          loading={isFetching}
          selectable={false}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
        <NewStoreModal
          clientId={clientId as string}
          active={clientStoreModalActive}
          setActive={setClientStoreModalActive}
        />
      </BlockStack>
    </Card>
  );
}

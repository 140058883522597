import { Modal, FormLayout } from "@shopify/polaris";
import { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldInput from "../../../../../components/TextFieldInput";
import { Store } from "../../../../../types/common.types";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import { useAddStore } from "./hooks/useAddStore";
import CheckboxInput from "../../../../../components/CheckboxInput";

interface ClientStoreModalProps {
  clientId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    id: yup.string().nullable(),
    client_id: yup.string().nullable(),
    name: yup.string().required(),
    url: yup.string().required(),
    bank_site: yup.boolean(),
  })
  .required();

export default function NewStoreModal({
  clientId,
  active,
  setActive,
}: ClientStoreModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<Store>({
    resolver: yupResolver(schema),
  });

  const addClientStore = useAddStore(clientId);

  const handleClose = () => {
    reset();
    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (store: Store) => {
    setLoading(true);

    try {
      await addClientStore(store);

      await queryClient.invalidateQueries([
        queryKeysConstants.clients,
        clientId,
        "stores",
      ]);

      handleClose();

      onShowToast("Store added", false);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title="Add Store"
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput control={control} label="Store Name" name="name" />
          <TextFieldInput control={control} label="URL" name="url" />
          <CheckboxInput
            control={control}
            label="is bank site"
            name="bank_site"
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

import {
  BlockStack,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { useState } from "react";
import { Client } from "../../../../../types/clients.types";
import { GridField } from "../../../../../components/GridField";

export default function ClientContactInformation({
  client,
  userRole,
}: {
  client: Client | null;
  userRole: string;
}) {
  const [, setEditContactInformationModalActive] = useState(false);

  return (
    <Card>
      <BlockStack gap="200">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            Contact Information
          </Text>
          {userRole === "admin" && (
            <Button
              variant="plain"
              onClick={() => setEditContactInformationModalActive(true)}
            >
              Edit
            </Button>
          )}
        </InlineStack>
        <InlineGrid columns="1" gap="300">
          <GridField name="Name">{client?.name}</GridField>
          {userRole === "admin" && (
            <GridField name="Email">{client?.email}</GridField>
          )}
          <GridField name="Company Name">
            {client?.company_name || "-"}
          </GridField>
          {userRole === "admin" && (
            <GridField name="Billing address">
              {client?.billing_address ? "" : "-"}
              {client?.billing_address?.street_address}
              <br />
              {client?.billing_address?.city} {client?.billing_address?.state}{" "}
              {client?.billing_address?.zip}
              <br />
              {client?.billing_address?.country}
            </GridField>
          )}
        </InlineGrid>
      </BlockStack>
    </Card>
  );
}

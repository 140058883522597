import { Button } from "@shopify/polaris";
import { useContext, useState } from "react";
import { Client, ClientStatus } from "../../../../../types/clients.types";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useUpdateClient } from "../../hooks/useUpdateClient";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";

export default function ActivateClientButton({ client }: { client: Client }) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);

  const updateClient = useUpdateClient();

  const onHandleSubmit = async () => {
    setLoading(true);

    try {
      await updateClient({
        ...client,
        status: ClientStatus.ACTIVE,
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.clients,
        client.id,
      ]);

      onShowToast("Client Activated", false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Button
      variant="primary"
      loading={loading}
      onClick={() => onHandleSubmit()}
    >
      Activate
    </Button>
  );
}

import { Frame, Navigation } from "@shopify/polaris";
import { PersonIcon } from "@shopify/polaris-icons";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useState } from "react";

import logo from "../config/logo";
import Header from "../components/Header";
import { ToastAlert } from "../components/ToastAlert";
import ClientsPageForManager from "../pages/ManagerDashboard/Clients";
import ClientPageForManager from "../pages/ManagerDashboard/Client";
import StorePage from "../pages/AdminDashboard/Clients/components/StoresTables/StorePage";

export default function ManagerDashboard() {
  const location = useLocation();
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const onMobileNavigationToggle = () => {
    setShowMobileNavigation(!showMobileNavigation);
  };

  const onHideMobileNavigation = () => {
    setShowMobileNavigation(false);
  };

  const navigationMarkup = (
    <Navigation onDismiss={onHideMobileNavigation} location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: "/manager/clients",
            label: "Clients",
            icon: PersonIcon,
          },
        ]}
      />
    </Navigation>
  );

  return (
    <Frame
      logo={logo}
      topBar={<Header onNavigationToggle={onMobileNavigationToggle} />}
      navigation={navigationMarkup}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={onHideMobileNavigation}
    >
      <Routes>
        <Route path="/clients" element={<ClientsPageForManager />} />
        <Route path="/clients/:clientId" element={<ClientPageForManager />} />

        <Route
          path="/clients/:clientId/stores/:storeId"
          element={<StorePage userRole="manager" />}
        />

        <Route path="*" element={<Navigate to="/manager/clients" />} />
      </Routes>
      <ToastAlert />
    </Frame>
  );
}

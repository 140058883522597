export default {
  agents: "agents",
  user: "user",
  clients: "client",
  stats: "stats",
  bills: "bills",
  stores: "stores",
  zendeskAgents: "zendesk-agents",
  reports: "reports",
  agentStores: "agent-stores",
  documents: "documents",
  clientsDocuments: "clients-documents",
  payments: "payments",
  usagePayments: "usage-payments",
  products: "products",
  usageRates: "usage-rates",
};

import {
  BlockStack,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  Link,
  Text,
} from "@shopify/polaris";
import { useState } from "react";
import { Client } from "../../../../../types/clients.types";
import EditCRMDetailsModal from "./EditCRMDetailsModal";
import { GridField } from "../../../../../components/GridField";
import { capitalize } from "../../../../../helpers/helpers";

export default function CRMDetails({
  client,
  userRole,
}: {
  client: Client;
  userRole: string;
}) {
  const [editZendeskDetailsModalActive, setEditZendeskDetailsModalActive] =
    useState(false);

  return (
    <Card>
      <BlockStack gap="200">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            CRM Details
          </Text>
          {userRole === "admin" && (
            <Button
              variant="plain"
              onClick={() => setEditZendeskDetailsModalActive(true)}
            >
              Edit
            </Button>
          )}
        </InlineStack>
        <InlineGrid columns="1" gap="300">
          <GridField name="Type">
            {client?.crm_details
              ? capitalize(client?.crm_details?.type.toLowerCase())
              : ""}
          </GridField>
          <GridField name="Name">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              target="_blank"
              url={`https://${client?.crm_details
                ?.subdomain}.${client?.crm_details?.type.toLowerCase()}.com`}
            >
              {client?.crm_details?.subdomain}
            </Link>
          </GridField>
          <GridField name="Email">{client?.crm_details?.email}</GridField>
          <GridField name="Password">{client?.crm_details?.password}</GridField>
        </InlineGrid>
        <EditCRMDetailsModal
          client={client as Client}
          active={editZendeskDetailsModalActive}
          setActive={setEditZendeskDetailsModalActive}
        />
      </BlockStack>
    </Card>
  );
}
